import { Box } from '@churni/styleguide';
import * as React from 'react';
import ChurniLogo from './assets/logo.svg';

export function Logo(props: any): React.ReactElement {
  return (
    <Box {...props} sx={{ lineHeight: '1rem' }}>
      <Box
        sx={{
          color: 'primary'
        }}
        as={ChurniLogo}
      ></Box>
    </Box>
  );
}
