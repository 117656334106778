import { routes, LandingPageContext } from '@churni/common';
import { Box, Link, Button, Icon } from '@churni/styleguide';

import * as React from 'react';
import { TabContext } from '../context';

import { getFilteredTabs } from '../tabs';

export function HeaderTabsDesktop(): React.ReactElement {
  const currentTab = React.useContext(TabContext);
  const isLandingPage = React.useContext(LandingPageContext);
  const tabs = getFilteredTabs(isLandingPage);

  return (
    <>
      {tabs.map(tab => (
        <HeaderDesktopTab
          key={tab.path}
          title={tab.title}
          path={tab.path}
          isActive={currentTab && currentTab.path === tab.path}
        />
      ))}
      <LoginTabs />
    </>
  );
}

function LoginTabs(): React.ReactElement {
  const isLandingPage = React.useContext(LandingPageContext);
  return (
    <>
      {!isLandingPage && (
        <Box pl={[3, 3, 5]}>
          <Link as="a" href={routes.toAppURL(routes.login())} variant={'nav'}>
            Sign in
          </Link>
        </Box>
      )}
      <Button
        as="a"
        href={routes.toAppURL(routes.signup())}
        className={'homepage-header-signup'}
        variant="secondary"
        ml={[3, 3, 5]}
      >
        Try it free <Icon.ArrowRight />
      </Button>
    </>
  );
}

export function HeaderDesktopTab(props: {
  title: string;
  path: string;
  isActive: boolean;
  external?: boolean;
}): React.ReactElement {
  const { title, path, isActive, external } = props;

  const linksProps = external
    ? { as: 'a', href: path, target: '_blank' }
    : { to: path };

  return (
    <Box pl={[3, 3, 5]}>
      <Link
        {...linksProps}
        variant={'nav'}
        className={isActive ? 'active' : ''}
      >
        {title}
      </Link>
    </Box>
  );
}
