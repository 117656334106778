import { Box, Flex, Grid, Text, Link } from '@churni/styleguide';
import * as React from 'react';
import { TabDefinition } from './tabs';
import { TabContext } from './context';
import { Header } from './Header';
import { Global } from '@emotion/core';
import {
  LogoWhite,
  SentryProvider,
  ErrorWrapper,
  LandingPageContext
} from '@churni/common';

import { SxStyleProp } from 'rebass';

export function MarketingLayout(props: {
  currentTab?: TabDefinition;
  children: React.ReactElement;
  sx: SxStyleProp;
  headerFixed?: boolean;
}): React.ReactElement {
  const {
    currentTab,
    children,
    headerFixed = true,

    sx
  } = props;

  const isLandingPage = React.useContext(LandingPageContext);
  const [scrollTop, setScrollTop] = React.useState(0);

  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fixed = headerFixed && scrollTop > 24;

  return (
    <>
      <LandingPageContext.Provider value={isLandingPage}>
        <SentryProvider />
        <Global
          styles={theme => {
            return {
              body: {
                fontFamily: theme.fonts.body,
                fontSize: theme.fontSizes[theme.text.body1.fontSize],
                fontWeight: theme.fontWeights[theme.text.body1.fontWeight],
                lineHeight: theme.lineHeights[theme.text.body1.lineHeight],
                letterSpacing: theme.text.body1.letterSpacing,
                color: theme.colors.text,
                backgroundColor: theme.colors.background,
                margin: 0,
                padding: 0,
                // overflowX: 'hidden',
                overflowY: 'scroll'
              }
            };
          }}
        />
        <ErrorWrapper>
          <TabContext.Provider value={currentTab}>
            <Box
              sx={
                headerFixed
                  ? {
                      zIndex: 5,
                      bg: 'marketingBG',
                      position: 'block',
                      width: '100%',
                      top: 0,
                      pt: 5,

                      pb: '12px',
                      ...(fixed
                        ? {
                            position: 'fixed',
                            pt: '12px',
                            borderBottom: 2,
                            mx: 0,
                            borderColor: 'border'
                          }
                        : {})
                    }
                  : {
                      zIndex: 5,
                      bg: 'marketingBG',
                      position: 'block',
                      width: '100%',
                      top: 0,
                      pt: 5
                    }
              }
            >
              <Header fixed={fixed} />
            </Box>
            <Flex
              justifyContent="space-between"
              flexDirection="column"
              sx={{ minHeight: 'calc(100vh - 92px)', bg: 'marketingBG', ...sx }}
            >
              <Box>
                <Box mt={fixed ? 92 : 0}>{children}</Box>
              </Box>
              <Flex
                py={3}
                sx={{
                  borderTop: 1,
                  borderColor: 'border',
                  textAlign: 'center',
                  bg: 'footer'
                }}
                justifyContent={'center'}
              >
                <Box>
                  <LogoWhite />
                  <Flex mt={2}>
                    <Box p={2}>
                      <Link as={'a'} href={'mailto:contact@churni.io'}>
                        <Text variant="body2" className="contactus">
                          Contact us
                        </Text>
                      </Link>
                    </Box>
                    <Box p={2}>
                      <Link to={'/terms/'}>
                        <Text variant="body2">Terms</Text>
                      </Link>
                    </Box>
                    <Box p={2}>
                      <Link to={'/privacy/'}>
                        <Text variant="body2">Privacy</Text>
                      </Link>
                    </Box>
                    {!isLandingPage && (
                      <Box p={2}>
                        <Link to={'/customer-cancellation/'}>
                          <Text variant="body2">Cancellation Guide</Text>
                        </Link>
                      </Box>
                    )}
                    {!isLandingPage && (
                      <Box p={2}>
                        <Link to={'/growth-calculator/'}>
                          <Text variant="body2">Growth Calculator</Text>
                        </Link>
                      </Box>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </TabContext.Provider>
        </ErrorWrapper>
      </LandingPageContext.Provider>
    </>
  );
}
