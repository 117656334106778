import { urls } from './config';
import * as querystring from 'querystring';

export function toWebsiteURL(path: string): string {
  return `${urls.website}${path}`;
}

export function toAppURL(path: string): string {
  return `${urls.app}${path}`;
}

export function root(): string {
  return '/';
}

export function blog(): string {
  return '/blog/';
}

export function docs(): string {
  return '/docs/';
}

export function demo(): string {
  return '/demo/';
}

export function product(): string {
  return '/product/';
}

export function post(slug: string): string {
  return `/blog/${slug}`;
}

export function pricing(): string {
  return '/pricing/';
}

export function features(): string {
  return '/features/';
}

export function sessionForm(formID: string): string {
  return `/s/${formID}`;
}

export function app(): string {
  return `/`;
}

export function onboarding(): string {
  return `${app()}getting-started/`;
}

export function googleSSO(qsParams: object = {}): string {
  Object.keys(qsParams).forEach(key => !qsParams[key] && delete qsParams[key]);
  const qs = querystring.stringify(qsParams);

  return `${app()}login/google/${qs ? `?${qs}` : ''}`;
}

export function login(qsParams: object = {}): string {
  Object.keys(qsParams).forEach(key => !qsParams[key] && delete qsParams[key]);
  const qs = querystring.stringify(qsParams);

  return `${app()}login/${qs ? `?${qs}` : ''}`;
}

export function signup(qsParams: object = {}): string {
  Object.keys(qsParams).forEach(key => !qsParams[key] && delete qsParams[key]);
  const qs = querystring.stringify(qsParams);

  return `${app()}signup/${qs ? `?${qs}` : ''}`;
}

export function forgot(qsParams: object = {}): string {
  Object.keys(qsParams).forEach(key => !qsParams[key] && delete qsParams[key]);
  const qs = querystring.stringify(qsParams);

  return `${app()}forgot/${qs ? `?${qs}` : ''}`;
}

export function newWorkspace(): string {
  return `${app()}new`;
}

export function billing(): string {
  return `${app()}settings/billing/`;
}

export function info(): string {
  return `${app()}settings/info/`;
}

export function account(): string {
  return `${app()}settings/account/`;
}

export function gettingStarted(): string {
  return `${app()}getting-started/`;
}

export function dashboard(): string {
  return `${app()}dashboard/overview`;
}

export function customers(
  props: {
    filters: object;
    from: string;
    to: string;
    search: string;
    periodicity: string;
    environmentID: string;
    offset: string;
  } = {}
): string {
  const {
    filters = {},
    from,
    to,
    search,
    periodicity,
    environmentID,
    offset
  } = props;

  const hasFilters = Object.values(filters).length > 0;

  const JSONFilters = hasFilters ? JSON.stringify(filters) : null;
  const qs = querystring.stringify({
    ...(hasFilters ? { filters: JSONFilters } : {}),
    ...(from ? { from } : {}),
    ...(to ? { to } : {}),
    ...(search ? { search } : {}),
    ...(periodicity && (!from || !to) ? { periodicity } : {}),
    ...(environmentID ? { environmentID } : {}),
    ...(offset ? { offset } : {})
  });

  return `${app()}dashboard/customers/${qs ? `?${qs}` : ''}`;
}

export function customer(customerID: string): string {
  return `${app()}dashboard/customers/${customerID}`;
}

export function insights(qsParams: object = {}): string {
  Object.keys(qsParams).forEach(key => !qsParams[key] && delete qsParams[key]);
  const qs = querystring.stringify(qsParams);

  return `${app()}dashboard/insights/${qs ? `?${qs}` : ''}`;
}

export function integrations(): string {
  return `${app()}integrations/webhooks/`;
}

export function webhooks(): string {
  return `${app()}integrations/webhooks/`;
}

export function formIntegrations(): string {
  return `${app()}integrations/widgets/`;
}

export function settings(): string {
  return `${app()}settings/info/`;
}

export function flow(): string {
  return `${app()}cancel-flow/`;
}

/*
 * Get the route for an invite.
 */
export function toInvite(workspaceID: string, inviteID: string): string {
  return `${app()}invite/${workspaceID}/${inviteID}/`;
}
