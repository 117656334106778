import * as React from 'react';
import { Button as NativeButton, ButtonProps } from 'rebass';
import { Text, Flex, Box } from '../primitives';
import { Icon } from '..';
import { usePrimitives } from '../../hooks/useTheme';

export const Button = React.forwardRef(function ButtonWithRef(
  props: ButtonProps & {
    icon?: React.ReactElement;
    rounded?: boolean;
    heightFlex?: boolean;
    loading?: boolean;
    size?: 'normal' | 'small';
  },
  ref: React.Ref<any>
): React.ReactElement {
  const {
    sx,
    children,
    disabled,
    icon = null,
    variant,
    rounded = false,
    heightFlex = false,
    loading = false,
    size,
    className,
    ...rest
  } = props;

  const { button } = usePrimitives();
  const isIconOnly = !children && icon;

  const variantStyles = button.variants[variant || button.defaultVariant];
  const loadingStyles =
    variantStyles && loading ? variantStyles['loading'] || {} : {};

  return (
    <NativeButton
      ref={ref}
      as={isIconOnly ? 'div' : 'button'}
      disabled={disabled}
      className={className}
      sx={{
        ...button.styles,
        ...variantStyles,
        ...(isIconOnly
          ? {
              padding: 0,
              display: 'block',
              minWidth: 'none'
            }
          : {}),
        ...(size === 'small' ? button.smallStyles : {}),
        ...(disabled ? { opacity: 0.5 } : {}),
        ...(heightFlex ? { height: '100%' } : { height: 'input' }),
        ...(rounded ? { borderRadius: 'rounded', outline: 'none' } : {}),
        ...loadingStyles,
        ...sx
      }}
      {...rest}
    >
      {loading ? (
        <Box>
          <Icon.Loading color="white" />
        </Box>
      ) : (
        <Text className={className}>
          <Flex alignItems="center">
            {icon}
            <Box ml={icon ? 1 : 0}>{children}</Box>
          </Flex>
        </Text>
      )}
    </NativeButton>
  );
});
