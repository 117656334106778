import {
  Container,
  Flex,
  Link,
  Box,
  Icon,
  Button,
  Dropdown
} from '@churni/styleguide';
import * as React from 'react';
import { HeaderTabsDesktop } from './HeaderTabsDesktop';
import { HeaderTabsMobile } from './HeaderTabsMobile';
import { Logo, LandingPageContext, useLocation, routes } from '@churni/common';

export function Header(props: { fixed: boolean }): React.ReactElement {
  const landingPage = React.useContext(LandingPageContext);
  const { location } = useLocation();

  const linkParams =
    landingPage && !location.pathname.match(/pricing/)
      ? {
          as: 'a',
          href: 'https://www.churni.io?utm_source=landing_page&utm_medium=logo'
        }
      : { to: landingPage || routes.root() };

  return (
    <Container>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex alignItems={'center'}>
          <Link {...linkParams}>
            <Logo className={'marketing-header-logo'} />
          </Link>
        </Flex>
        <Box
          sx={{
            display: [
              'none',
              'none',
              props.fixed || !landingPage ? 'flex' : 'none'
            ],

            alignItems: 'center'
          }}
        >
          <HeaderTabsDesktop />
        </Box>
        <Box sx={{ display: ['flex', 'flex', 'none'] }}>
          <Dropdown
            position={'west'}
            toggle={
              <Button variant={'text'} icon={<Icon.Menu size={'large'} />} />
            }
          >
            <HeaderTabsMobile />
          </Dropdown>
        </Box>
      </Flex>
    </Container>
  );
}
