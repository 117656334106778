import * as React from 'react';
import { Box } from '../primitives';

interface IconProps {
  // Should we use the size of the SVG or the font ?
  size?: 'text' | 'svg' | 'small' | 'medium' | 'large';
  color: string;
}

const sizeStyles = {
  svg: {},
  text: {
    width: '1em',
    height: '1em'
  },
  small: {
    width: 14,
    height: 14
  },
  normal: {
    width: 16,
    height: 16
  },
  medium: {
    width: 18,
    height: 18
  },
  large: {
    width: 24,
    height: 24
  }
};

export function createIcon(Icon: React.ComponentType): any {
  return function IconC(props: IconProps) {
    const { size = 'medium', color, ...rest } = props;

    return (
      <Box
        as={Icon}
        sx={{ verticalAlign: 'middle', ...sizeStyles[size], fill: color }}
        {...rest}
      />
    );
  };
}
