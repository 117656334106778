import * as React from 'react';
import { Link as RouterLink } from 'gatsby';
import { globalHistory } from '@reach/router';
import { Box, Text, TextProps } from '../primitives';
import { usePrimitives } from '../..';

export const Link = React.forwardRef(function RawLinkWithRef(
  props:
    | { children: React.ReactElement; to: string }
    | (TextProps & { children: string; variant: string; to: string }),
  ref: React.Ref<any>
): React.ReactElement {
  const [startTransition] = React.useTransition({ timeoutMs: 3000 });
  const { children, variant, sx, as = RouterLink, to, ...rest } = props;
  const { link } = usePrimitives();

  const isChildrenString = typeof children === 'string';

  const clickWithTransition = (event: React.SyntheticEvent) => {
    if (props.onClick) {
      props.onClick();
      return;
    }

    if (props.as) {
      return;
    }

    const shouldNavigate =
      !event.defaultPrevented &&
      event.button === 0 &&
      !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

    if (shouldNavigate) {
      event.preventDefault();

      startTransition(() => {
        return globalHistory.navigate(to);
      });
    }
  };

  if (isChildrenString) {
    return (
      <Text
        {...rest}
        ref={ref}
        sx={{
          ...link.styles,
          ...link.variants[variant || link.defaultVariant],
          ...sx
        }}
        as={as}
        to={to}
        onClick={clickWithTransition}
      >
        {children}
      </Text>
    );
  }

  return (
    <Box
      {...rest}
      ref={ref}
      sx={{
        ...link.styles,
        ...link.variants[variant || link.defaultVariant],
        ...sx
      }}
      as={as}
      to={to}
      onClick={clickWithTransition}
    >
      {children}
    </Box>
  );
});
