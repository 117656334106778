import { User as AuthUser } from 'firebase';
import {
  useFirebaseApp,
  useAuth,
  useFirestore,
  useUser,
  useStorage,
  useAnalytics
} from 'reactfire';

export const useFirebase = useFirebaseApp;
export { useAuth, useStorage, useAnalytics, useFirestore };

export function useDatabase() {
  return useFirestore();
}

/*
 * Main hook to access the auth user and be updated when we changed.
 */
export function useAuthUser(): firebase.User | null {
  return useUser();
}

/*
 * Get the current firebase user.
 */
export function useCurrentUser(): AuthUser {
  return useUser();
}
