import * as React from 'react';

import { ALIGN, Box, POSITION } from '../..';
import { CloseableCriterias, PortalContent, PortalToggeable } from '../Portal';

/*
 * Easy to use portal for toggeable menu/popover.
 */
export const Dropdown = React.forwardRef(function DropdownWithRef(
  props: CloseableCriterias & {
    children: React.ReactElement;
    toggle: React.ReactElement;
    align?: ALIGN;
    position?: POSITION;
    onClose?: () => void;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const {
    children,
    toggle,
    onClose,
    align = 'middle',
    position = 'south',
    ...rest
  } = props;

  return (
    <PortalToggeable
      ref={ref}
      onClose={onClose}
      anchor={anchorProps => {
        const { ref: anchorRef, onToggle } = anchorProps;

        const onClickToggle = (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          onToggle();
        };

        return typeof toggle === 'function' ? (
          toggle(anchorProps)
        ) : (
          <Box variant={'clickable'} ref={anchorRef} onClick={onClickToggle}>
            {toggle}
          </Box>
        );
      }}
      {...rest}
    >
      <PortalContent align={align} position={position}>
        <Box sx={{ borderRadius: 'rounded', bg: 'background' }}>
          <Box color={'text'} elevation={3} minWidth={150}>
            {children}
          </Box>
        </Box>
      </PortalContent>
    </PortalToggeable>
  );
});
