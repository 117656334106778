import * as React from 'react';
import { createPortal } from 'react-dom';

const CAN_USE_DOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

/*
 * Setup and cache the DIV element used to render all portals.
 */
function usePortalRoot(): HTMLElement | null {
  const portalRoot = React.useRef<HTMLElement | null>(null);

  if (!portalRoot.current && CAN_USE_DOM) {
    const found = document.getElementById('portals-root');

    if (found) {
      portalRoot.current = found;
    } else {
      portalRoot.current = document.createElement('div');
      portalRoot.current.setAttribute('id', 'portals-root');

      document.body.appendChild(portalRoot.current);
    }
  }

  return portalRoot.current;
}

/*
 * Use a DIV in the dom that can be used for rendering a portal.
 */
function usePortalContainer(): HTMLElement | null {
  const portalEl = React.useRef<HTMLElement | null>(null);
  const root = usePortalRoot();

  if (!portalEl.current && root) {
    portalEl.current = document.createElement('div');
    root.appendChild(portalEl.current);
  }

  // Remove the element on unmount
  React.useEffect(
    () => () => {
      if (portalEl.current && root) {
        root.removeChild(portalEl.current);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return portalEl.current;
}

/*
 * Primitive portal component to render an absolute portal.
 */
const PortalAbsolute = React.forwardRef(
  (
    props: {
      children: React.ReactNode;
    },
    ref: React.Ref<any>
  ): React.ReactNode => {
    const { children } = props;
    const container = usePortalContainer();

    React.useImperativeHandle(ref, () => ({
      // Get the portal container,
      getContainer: () => container
    }));

    return container ? createPortal(children, container) : null;
  }
);
PortalAbsolute.displayName = 'PortalAbsolute';

export { PortalAbsolute };
