import * as React from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { Flex, Box, Text } from '@churni/styleguide';
import { LogoWhite } from './LogoWhite';

export function ErrorWrapper(props: {
  children: React.ReactElement;
}): React.ReactElement {
  const { children } = props;

  return <ErrorBoundary fallback={<ErrorFallback />}>{children}</ErrorBoundary>;
}

function ErrorFallback(): React.ReactElement {
  return (
    <Flex
      css={{ minHeight: '100vh' }}
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      <Box elevation={2} p={3}>
        <Box>
          <Text variant="heading1">Oops, something went wrong :(</Text>
        </Box>
        <Box mt={3}>
          <Text>
            Try to refresh this page or feel free to contact us if the problem
            persists.
          </Text>
        </Box>
      </Box>
      <Box mt={1}>
        <LogoWhite />
      </Box>
    </Flex>
  );
}
