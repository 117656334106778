import * as React from 'react';

import { PortalRelative } from './PortalRelative';
import { CloseableCriterias, useCloseable } from './useCloseable';

/*
 * Easy to use portal for toggeable menu/popover.
 */
const PortalToggeable = React.forwardRef(
  (
    props: CloseableCriterias & {
      anchor: (props: {
        ref: React.Ref<any>;
        opened: boolean;
        onToggle: () => void;
      }) => React.ReactNode;
    },
    ref: React.Ref<any>
  ): React.ReactElement => {
    const {
      anchor,
      closeOnEsc,
      closeOnOutsideClick,
      closeOnScroll,
      closeOnClick,
      ...rest
    } = props;
    const [opened, setOpened] = React.useState(false);
    const portalRef = React.useRef();

    React.useImperativeHandle(ref, () => ({
      open: () => {
        setOpened(true);
      },
      close: () => {
        setOpened(false);
      },
      opened
    }));

    useCloseable(portalRef, {
      opened,
      closeOnEsc,
      closeOnOutsideClick,
      closeOnScroll,
      closeOnClick,
      onClose: () => {
        setOpened(false);
      }
    });

    const onToggle = () => {
      setOpened(prev => !prev);
    };

    return (
      <PortalRelative
        {...rest}
        ref={portalRef}
        opened={opened}
        anchor={({ ref: anchorRef }) =>
          anchor({ ref: anchorRef, opened, onToggle })
        }
      />
    );
  }
);

PortalToggeable.displayName = 'PortalToggeable';

export { PortalToggeable };
