import { routes, LandingPageContext } from '@churni/common';

import { Menu } from '@churni/styleguide';
import * as React from 'react';
import { TabContext } from '../context';

import { getFilteredTabs } from '../tabs';

export function HeaderTabsMobile(): React.ReactElement {
  const currentTab = React.useContext(TabContext);
  const isLandingPage = React.useContext(LandingPageContext);

  const tabs = getFilteredTabs(isLandingPage);

  return (
    <Menu sx={{ width: [200, 400], borderRadius: 'rounded' }}>
      {tabs.map(tab => (
        <HeaderMobileTab
          key={tab.path}
          title={tab.title}
          path={tab.path}
          isActive={currentTab && currentTab.path === tab.path}
        />
      ))}
      <LoginTabs />
    </Menu>
  );
}

function LoginTabs(): React.ReactElement {
  return (
    <>
      <HeaderMobileTab
        title={'Sign in'}
        path={routes.toAppURL(routes.login())}
      />
      <HeaderMobileTab
        title={'Sign up'}
        path={routes.toAppURL(routes.signup())}
      />
    </>
  );
}

export function HeaderMobileTab(props: {
  title: string;
  path: string;
  isActive: boolean;
}): React.ReactElement {
  const { path, title } = props;

  return (
    <Menu.Item as={'a'} sx={{ textDecoration: 'none' }} href={path}>
      {title}
    </Menu.Item>
  );
}
