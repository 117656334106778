import * as React from 'react';
import { Box, BoxProps } from '../primitives';

export function Container(props: BoxProps): React.ReactElement {
  const { children, maxWidth, sx = {}, ...rest } = props;

  return (
    <Box
      sx={{
        flex: 1,
        px: 2,
        mx: 'auto',
        maxWidth: maxWidth || 1172,
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
