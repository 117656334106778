import * as React from 'react';
import { sentry } from './config';
import { useAuthUser } from '@churni/fire';

export function SentryProviderWithUser(props: {}): React.ReactElement {
  const user = useAuthUser();

  React.useEffect(() => {
    import('@sentry/browser').then(Sentry => {
      Sentry.init({
        dsn: sentry.web
      });
      if (user) {
        Sentry.configureScope(function(scope) {
          scope.setUser({ id: user.uid, email: user.email });
        });
      }
    });
  }, [user]);

  return null;
}

export function SentryProvider(props: {}): React.ReactElement {
  React.useEffect(() => {
    import('@sentry/browser').then(Sentry => {
      Sentry.init({
        dsn: sentry.web
      });
    });
  }, []);

  return null;
}
