import * as React from 'react';
import { Box } from '../primitives';

import { PortalAbsolute } from './PortalAbsolute';

export interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface Directions {
  horizontal: 'left' | 'right';
  vertical: 'top' | 'bottom';
}

export type PortalAnchorProp = (props: {
  ref: React.Ref<any>;
}) => React.ReactNode;
export type PortalChildrenProp =
  | React.ReactNode
  | ((pos: { position: Rect; directions: Directions }) => React.ReactNode);

// Imperative methods for the Portal ref
interface PortalMethods {
  // Refresh the position of the portal
  update: () => void;
  // Get the portal container,
  getContainer: () => HTMLElement;
  // Get the portal anchor
  getAnchor: () => any;
}

/*
 * Portal that render with the position to help position content around an anchor.
 */
const PortalRelative = React.forwardRef(
  (
    props: {
      opened?: boolean;
      fixed?: boolean;
      anchor: PortalAnchorProp;
      children: PortalChildrenProp;
      anchorRef?: React.Ref<any>;
      wrapper?: React.ComponentType<{}>;
    },
    ref: React.Ref<any>
  ): React.ReactElement => {
    const defaultAnchorRef = React.useRef();
    const {
      opened = true,
      fixed = false,
      anchor,
      wrapper = Box,
      anchorRef = defaultAnchorRef,
      children
    } = props;
    const portalRef = React.useRef();
    const [state, setState] = React.useState(null);

    const getContainer = () => {
      const portal = portalRef.current;

      if (!portal) {
        return null;
      }

      return portal.getContainer();
    };

    const update = () => {
      if (!opened) {
        return;
      }

      const anchorNode = anchorRef.current;
      const container = getContainer();

      if (!container) {
        return;
      }

      container.style.display = opened ? 'block' : 'none';

      if (!anchorNode || !(anchorNode instanceof HTMLElement)) {
        return;
      }

      const {
        top: fixedTop,
        left,
        width,
        height
      } = anchorNode.getBoundingClientRect();

      const top = fixed
        ? fixedTop
        : // Absolute in body, take scroll into account
          fixedTop + window.scrollY;

      setState({
        position: {
          top,
          left,
          width,
          height
        },
        directions: {
          vertical: top > window.innerHeight / 2 ? 'top' : 'bottom',
          horizontal: left > window.innerWidth / 2 ? 'left' : 'right'
        }
      });
    };

    React.useEffect(() => {
      update();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opened]);

    React.useImperativeHandle(
      ref,
      (): PortalMethods => ({
        update,
        getContainer,
        getAnchor: () => anchorRef.current
      })
    );

    return (
      <>
        {anchor({ ref: anchorRef })}

        <PortalAbsolute ref={portalRef}>
          {(() => {
            if (!state || !opened) {
              return null;
            }

            return typeof children === 'function'
              ? children(state)
              : React.createElement(
                  wrapper,
                  {
                    onClick: event => {
                      event.stopPropagation();
                    },
                    style: {
                      position: fixed ? 'fixed' : 'absolute',
                      ...state.position
                    }
                  },
                  children
                );
          })()}
        </PortalAbsolute>
      </>
    );
  }
);

PortalRelative.displayName = 'PortalRelative';

export { PortalRelative };
