import GoogleLogoSVG from './svgs/GoogleLogo.svg';

import AlertTriangleSVG from './svgs/AlertTriangle.svg';
import BillingSVG from './svgs/Billing.svg';
import BriefCaseSVG from './svgs/BriefCase.svg';
import CheckSVG from './svgs/Check.svg';
import ChevronDownSVG from './svgs/ChevronDown.svg';
import DatabaseSVG from './svgs/Database.svg';

import AtSVG from './svgs/At.svg';
import ArrowLeftSVG from './svgs/ArrowLeft.svg';
import ArrowRightSVG from './svgs/ArrowRight.svg';
import BarChartSVG from './svgs/BarChart.svg';
import BoldsSVG from './svgs/Bold.svg';
import BookOpenSVG from './svgs/BookOpen.svg';
import BoxSVG from './svgs/Box.svg';
import CalendarSVG from './svgs/Calendar.svg';
import CodeSVG from './svgs/Code.svg';
import CheckSquareSVG from './svgs/CheckSquare.svg';
import ChevronUpSVG from './svgs/ChevronUp.svg';
import ChevronLeftSVG from './svgs/ChevronLeft.svg';
import ChevronRightSVG from './svgs/ChevronRight.svg';
import CornerDownRightSVG from './svgs/CornerDownRight.svg';
import DownloadSVG from './svgs/Download.svg';
import EditSVG from './svgs/Edit.svg';
import EmailSVG from './svgs/Email.svg';
import ExternalLinkSVG from './svgs/ExternalLink.svg';
import FacebookSVG from './svgs/Facebook.svg';
import FileSVG from './svgs/File.svg';
import FileTextSVG from './svgs/FileText.svg';
import FilterSVG from './svgs/Filter.svg';
import GridSVG from './svgs/Grid.svg';
import GiftSVG from './svgs/Gift.svg';
import HelpCircleSVG from './svgs/HelpCircle.svg';
import Heading1SVG from './svgs/Heading1.svg';
import Heading2SVG from './svgs/Heading2.svg';
import Heading3SVG from './svgs/Heading3.svg';
import HomeSVG from './svgs/Home.svg';
import ItalicSVG from './svgs/Italic.svg';
import InfoSVG from './svgs/Info.svg';
import LayersSVG from './svgs/Layers.svg';
import LinkSVG from './svgs/Link.svg';
import LinkedinSVG from './svgs/Linkedin.svg';
import ListOrderedSVG from './svgs/ListOrdered.svg';
import ListTasksSVG from './svgs/ListTasks.svg';
import ListUnorderedSVG from './svgs/ListUnordered.svg';
import MoreHorizontalSVG from './svgs/MoreHorizontal.svg';
import MessageSVG from './svgs/Message.svg';
import MessageSquareSVG from './svgs/MessageSquare.svg';
import MenuSVG from './svgs/Menu.svg';
import MoonSVG from './svgs/Moon.svg';
import MoreVerticalSVG from './svgs/MoreVertical.svg';
import MousePointerSVG from './svgs/MousePointer.svg';
import LoadingSVG from './svgs/Loading.svg';
import LogoutSVG from './svgs/Logout.svg';
import ParagraphSVG from './svgs/Paragraph.svg';
import PercentSVG from './svgs/Percent.svg';
import PlaySVG from './svgs/Play.svg';
import PlusSVG from './svgs/Plus.svg';
import PlusCircleSVG from './svgs/PlusCircle.svg';
import ResetSVG from './svgs/Reset.svg';
import SearchSVG from './svgs/Search.svg';
import SettingsSVG from './svgs/Settings.svg';
import SlidersSVG from './svgs/Sliders.svg';
import ShareSVG from './svgs/Share.svg';
import SunSVG from './svgs/Sun.svg';
import TrashcanSVG from './svgs/Trashcan.svg';
import ToolSVG from './svgs/Tool.svg';
import TypeSVG from './svgs/Type.svg';
import TwitterSVG from './svgs/Twitter.svg';
import UnlockSVG from './svgs/Unlock.svg';
import UserSVG from './svgs/User.svg';
import UsersSVG from './svgs/Users.svg';
import ZapSVG from './svgs/Zap.svg';

import XSVG from './svgs/X.svg';

import { createIcon } from './createIcon';

export const GoogleLogo = createIcon(GoogleLogoSVG);

export const At = createIcon(AtSVG);
export const AlertTriangle = createIcon(AlertTriangleSVG);
export const ArrowLeft = createIcon(ArrowLeftSVG);
export const ArrowRight = createIcon(ArrowRightSVG);
export const X = createIcon(XSVG);
export const BarChart = createIcon(BarChartSVG);
export const BriefCase = createIcon(BriefCaseSVG);
export const BookOpen = createIcon(BookOpenSVG);
export const Box = createIcon(BoxSVG);
export const Card = createIcon(BillingSVG);
export const Calendar = createIcon(CalendarSVG);
export const Check = createIcon(CheckSVG);
export const CheckSquare = createIcon(CheckSquareSVG);
export const ChevronDown = createIcon(ChevronDownSVG);
export const ChevronUp = createIcon(ChevronUpSVG);
export const ChevronLeft = createIcon(ChevronLeftSVG);
export const ChevronRight = createIcon(ChevronRightSVG);
export const CornerDownRight = createIcon(CornerDownRightSVG);
export const Code = createIcon(CodeSVG);
export const Database = createIcon(DatabaseSVG);
export const Download = createIcon(DownloadSVG);
export const Edit = createIcon(EditSVG);
export const Email = createIcon(EmailSVG);
export const ExternalLink = createIcon(ExternalLinkSVG);
export const Facebook = createIcon(FacebookSVG);
export const File = createIcon(FileSVG);
export const Filter = createIcon(FilterSVG);
export const FileText = createIcon(FileTextSVG);
export const Grid = createIcon(GridSVG);
export const Gift = createIcon(GiftSVG);
export const HelpCircle = createIcon(HelpCircleSVG);
export const Heading1 = createIcon(Heading1SVG);
export const Heading2 = createIcon(Heading2SVG);
export const Heading3 = createIcon(Heading3SVG);
export const Home = createIcon(HomeSVG);
export const Message = createIcon(MessageSVG);
export const MessageSquare = createIcon(MessageSquareSVG);
export const MoreVertical = createIcon(MoreVerticalSVG);
export const MousePointer = createIcon(MousePointerSVG);
export const MoreHorizontal = createIcon(MoreHorizontalSVG);
export const Menu = createIcon(MenuSVG);
export const Moon = createIcon(MoonSVG);
export const Layers = createIcon(LayersSVG);
export const Link = createIcon(LinkSVG);
export const Linkedin = createIcon(LinkedinSVG);
export const Logout = createIcon(LogoutSVG);
export const Loading = createIcon(LoadingSVG);
export const Percent = createIcon(PercentSVG);
export const Settings = createIcon(SettingsSVG);
export const User = createIcon(UserSVG);
export const Users = createIcon(UsersSVG);
export const Bold = createIcon(BoldsSVG);
export const Reset = createIcon(ResetSVG);
export const Italic = createIcon(ItalicSVG);
export const Info = createIcon(InfoSVG);
export const Paragraph = createIcon(ParagraphSVG);
export const Play = createIcon(PlaySVG);
export const Plus = createIcon(PlusSVG);
export const PlusCircle = createIcon(PlusCircleSVG);
export const Trashcan = createIcon(TrashcanSVG);
export const ListOrdered = createIcon(ListOrderedSVG);
export const ListUnordered = createIcon(ListUnorderedSVG);
export const ListTasks = createIcon(ListTasksSVG);
export const Unlock = createIcon(UnlockSVG);
export const Search = createIcon(SearchSVG);
export const Share = createIcon(ShareSVG);
export const Sliders = createIcon(SlidersSVG);
export const Sun = createIcon(SunSVG);
export const Tool = createIcon(ToolSVG);
export const Type = createIcon(TypeSVG);
export const Twitter = createIcon(TwitterSVG);
export const Zap = createIcon(ZapSVG);
