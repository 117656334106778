import { routes } from '@churni/common';

export interface TabDefinition {
  path: string;
  title: string;
}

export const tabs = {
  // product: {
  //   path: routes.product(),
  //   title: 'Product'
  // },
  pricing: {
    path: routes.pricing(),
    title: 'Pricing',
    shouldDisplayInLandingPage: false
  },
  blog: {
    path: routes.blog(),
    title: 'Blog',
    shouldDisplayInLandingPage: false
  },
  docs: {
    path: routes.docs(),
    title: 'Docs',
    shouldDisplayInLandingPage: false
  }
};

export const getFilteredTabs = (isLandingPage: boolean) => {
  return Object.values(tabs).filter(lp => {
    return isLandingPage ? !!lp.shouldDisplayInLandingPage : true;
  });
};
