import * as React from 'react';
import { BoxProps, SxStyleProp } from 'rebass';
import { Box, Link, Text, Flex } from '../primitives';

const MenuContext = React.createContext('light');

/*
 * An entry in a menu. Designed to contain inline text by default. Can have an icon.
 *
 * Example:
 *
 * <Menu.Item icon={<IconPlus />} hint={'Ctrl +'}>
 *   Add
 * </Menu.Item>
 */
function MenuItem(props: {
  children: React.ReactElement | string;
  sx?: SxStyleProp;
  // Adds an icon
  icon?: React.ReactNode;
  // Highlight
  active?: boolean;
  to?: string;
  onClick?: () => void;
}): React.ReactElement {
  const {
    icon,
    children,
    to,
    onClick,
    active = false,
    sx = {},
    ...rest
  } = props;

  const variant = React.useContext(MenuContext);

  return (
    <MenuItemWrapper
      {...rest}
      to={to}
      onClick={onClick}
      sx={{
        verticalAlign: 'middle',
        px: 2,
        ...variants[variant],
        ...sx
      }}
      className={active ? 'active' : ''}
    >
      <Flex alignItems={'center'} py={'4px'}>
        <Box color="text">{icon}</Box>
        <Box ml={1}>
          <Text variant={'subtitle4'}>{children}</Text>
        </Box>
      </Flex>
    </MenuItemWrapper>
  );
}

function MenuHeader(props: {
  children: React.ReactNode;
  // Adds an icon
  icon?: React.ReactNode;
}): React.ReactElement {
  const { icon, children } = props;

  const variant = React.useContext(MenuContext);

  return (
    <Box
      sx={{
        verticalAlign: 'middle',

        ...variants[variant],
        ':hover': { background: 'inherit' },
        borderBottom: 1,
        borderColor: 'border'
      }}
      py={'4px'}
      px={2}
    >
      {icon} <Text variant="subtitle4">{children}</Text>
    </Box>
  );
}

const variants = {
  dark: {
    color: 'text',

    '&.active': {
      background: 'rgba(255, 255, 255, 0.3)'
    },
    ':hover': {
      background: 'rgba(255, 255, 255, 0.08)'
    }
  },
  light: {
    color: 'text',

    '&.active': {
      background: 'rgba(0, 0, 0, 0.1)',

      '&:first-child': {
        borderTopLeftRadius: 'rounded',
        borderTopRightRadius: 'rounded'
      },
      '&:last-child': {
        borderBottomLeftRadius: 'rounded',
        borderBottomRightRadius: 'rounded'
      }
    },
    ':hover': {
      color: 'primary'
      // background: 'rgba(0, 0, 0, 0.08)'
    }
  }
};

function MenuItemWrapper(props: {
  to: string;
  onClick: () => void;
  children: React.ReactElement;
}): React.ReactElement {
  const { to, children, ...rest } = props;

  if (to) {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <Box variant={'clickable'} {...rest}>
      {children}
    </Box>
  );
}

/*
 * Container for the menu.
 */
function Menu(
  props: BoxProps & {
    children: React.ReactElement | React.ReactElement[];
    variant?: 'light' | 'dark';
  }
): React.ReactElement {
  const { children, variant = 'light', ...rest } = props;

  return (
    <MenuContext.Provider value={variant}>
      <Box minWidth={225} {...rest}>
        {children}
      </Box>
    </MenuContext.Provider>
  );
}

Menu.Item = MenuItem;
Menu.Header = MenuHeader;

export { Menu };
