import * as React from 'react';
import { SxStyleProp } from 'rebass';
import { Box } from '../..';

export type POSITION = 'north' | 'east' | 'south' | 'west';
export type ALIGN = 'start' | 'middle' | 'end';

export function PortalContent(props: {
  align: ALIGN;
  position: POSITION;
  children: React.ReactElement;
  sx?: SxStyleProp;
}): React.ReactElement {
  const { align = 'middle', position = 'south', sx = {}, children } = props;

  return (
    <Box
      sx={{
        ...styles.container,
        ...getStylesForAlign(position, align),
        ...getStylesForPosition(position),
        ...sx
      }}
    >
      {children}
    </Box>
  );
}

const styles = {
  container: {
    position: 'absolute',
    zIndex: 10
  }
};

/*
 * Returns the specific CSS for arrows with given position
 */
function getStylesForPosition(position: POSITION): SxStyleProp {
  // Use padding so that there is no gap, when hovering from the element to the popover
  switch (position) {
    case 'north':
      return {
        bottom: '100%',
        pb: 1
      };
    case 'east':
      return {
        top: '-50%',
        left: '100%',
        pl: 1
      };
    case 'west':
      return {
        top: '-50%',
        right: '100%',
        pr: 1
      };
    default:
    case 'south':
      return {
        top: '100%',
        pt: 1
      };
  }
}

/*
 * Return the alignement style for the given position and align.
 */
function getStylesForAlign(position: POSITION, align: ALIGN): SxStyleProp {
  const vertical = {
    start: {
      left: '50%'
    },
    middle: {
      left: '50%',
      transform: 'translateX(-50%)'
    },
    end: {
      right: '50%'
    }
  };

  const horizontal = {
    start: {
      top: '50%'
    },
    middle: {
      top: '50%',
      bottom: 'auto'
    },
    end: {
      bottom: '50%'
    }
  };

  return {
    north: vertical,
    south: vertical,
    east: horizontal,
    west: horizontal
  }[position][align];
}
