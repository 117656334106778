import * as React from 'react';

export interface CloseableCriterias {
  closeOnEsc?: boolean;
  closeOnOutsideClick?: boolean;
  closeOnScroll?: boolean;
  closeOnClick?: boolean;
}

export type CloseableOptions = CloseableCriterias & {
  opened: boolean;
  onClose: () => void;
};

/*
 * Add auto-closing behavior to a Portal.
 * Triggers the provided onClose when it should be closed.
 */
export function useCloseable(
  portalRef: React.Ref<any>,
  options: CloseableOptions
) {
  const {
    onClose,
    opened = true,
    closeOnEsc = true,
    closeOnOutsideClick = true,
    closeOnScroll = true,
    closeOnClick = false
  } = options;

  const onCloseRef = React.useRef(onClose);
  onCloseRef.current = onClose;

  // Close on scroll
  React.useEffect(() => {
    if (!closeOnScroll || !opened) {
      return undefined;
    }

    const initialYOffset = window.pageYOffset;

    const onScroll = () => {
      const scrollChange = Math.abs(window.pageYOffset - initialYOffset);
      if (scrollChange > 10 && portalRef.current) {
        onCloseRef.current();
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, closeOnScroll]);

  // Close on escape
  React.useEffect(() => {
    if (!closeOnEsc || !opened) {
      return undefined;
    }

    const onDocumentKeyDown = (event: KeyboardEvent) => {
      const ESCAPE_KEY = 27;

      if (event.keyCode === ESCAPE_KEY && portalRef.current) {
        onCloseRef.current();
      }
    };

    document.addEventListener('keydown', onDocumentKeyDown);
    return () => {
      document.removeEventListener('keydown', onDocumentKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, closeOnEsc]);

  // Close on click in the portal itself
  React.useEffect(() => {
    const portal = portalRef.current;

    if (!closeOnClick || !portal || !opened) {
      return undefined;
    }

    const portalRoot = portal.getContainer();
    if (!portalRoot) {
      return undefined;
    }

    let clicked = false;

    // We listen to click on document before react triggers its onClick
    const onSelfClick = (event: MouseEvent<HTMLElement>) => {
      clicked = portalRoot.contains(event.target);
    };

    // But we close it after react triggers it
    const onSelfClickAfterReact = () => {
      if (clicked) {
        // Clicked on an element inside the portal
        onCloseRef.current();
      }
    };

    document.addEventListener('click', onSelfClick, true);
    document.addEventListener('click', onSelfClickAfterReact);
    return () => {
      document.removeEventListener('click', onSelfClick, true);
      document.removeEventListener('click', onSelfClickAfterReact);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, closeOnClick]);

  // Close on outside click
  React.useEffect(() => {
    if (!closeOnOutsideClick || !opened) {
      return undefined;
    }

    const onDocumentClick = (event: MouseEvent | TouchEvent) => {
      const portal = portalRef.current;
      if (!closeOnOutsideClick || !portal) {
        return;
      }

      const portalRoot = portal.getContainer();

      if (!portalRoot || (event.button && event.button !== 0)) {
        // Not a click, or portal not mounted
        return;
      }

      if (portalRoot.contains(event.target)) {
        // Clicked on an element inside the portal
        return;
      }

      // The container covers the anchor, if there's one
      const anchor = portal.getAnchor ? portal.getAnchor() : null;
      if (anchor && eventWithinRect(event, anchor.getBoundingClientRect())) {
        // Clicked inside the anchor. Because of `pointer-events: none` this manual
        // computation was needed
        return;
      }
      onCloseRef.current();
    };

    document.addEventListener('click', onDocumentClick, true);
    document.addEventListener('touchstart', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick, true);
      document.removeEventListener('touchstart', onDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, closeOnOutsideClick]);
}

/*
 * True if the given events coordinate are within the given node rect
 */
function eventWithinRect(event: MouseEvent, rect: DOMRect): boolean {
  const { scrollX, scrollY } = window;
  return (
    event.pageX > rect.left + scrollX &&
    event.pageX < rect.right + scrollX &&
    event.pageY > rect.top + scrollY &&
    event.pageY < rect.bottom + scrollY
  );
}
