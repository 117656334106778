import {
  Router,
  navigate,
  Redirect as NativeRedirect,
  LocationContext
} from '@reach/router';
import { cold } from 'react-hot-loader';
import * as routes from './routes';

// Fix a bug with HMR : https://github.com/reach/router/issues/153
const Redirect = cold(NativeRedirect);

export { routes, navigate, Router, Redirect, LocationContext };
